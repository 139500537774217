/* global footerSectionComponent */
class FooterSectionCustomComponent extends footerSectionComponent{

    getComputed() {
        let superComputed = super.getComputed();
        Object.assign(superComputed, {
            enableLK:function () {
                return this.$store.state.setting.EnableLK;
            },
            linkLK: function (){
                let link = "#";
                if(this.$store.state.setting.LKLink)
                    link = this.$store.state.setting.LKLink;
                return link;
            },
            enableIG:function () {
                return this.$store.state.setting.EnableIG;
            },
            linkIG: function (){
                let link = "https://instagram.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.IGLink;
                return link;
            },
            enableFB:function () {
                return this.$store.state.setting.EnableFB;
            },
            linkFB: function (){
                let link = "https://instagram.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.FBLink;
                return link;
            },
            enableTW:function () {
                return this.$store.state.setting.EnableTW;
            },
            linkTW: function (){
                let link = "https://twitter.com";
                if(this.$store.state.setting.IGLink)
                    link = this.$store.state.setting.TWLink;
                return link;
            },
        });
        return superComputed;
    }

    getTemplate() {
        return `<footer class="container-fluid" v-if="mustRender">
                  <div class="container py-3">
                    <div class="row">
                        <div class="col-6 col-md-3">
                            <p class="footer-title mb-2">Quienes somos</p>
                            <ul class="list-unstyled">
                                <li>
                                    <router-link to="/about">La Empresa</router-link>
                                </li>
                                <li>
                                    <router-link to="/terms">{{tr('Terms and conditions')}}</router-link>
                                </li>
                                <li>
                                    <router-link to="/why">Por qué Ofidirect?</router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy">Política de privacidad</router-link>
                                </li>
                                </li>
                                <li>
                                    <router-link to="/complaint">Libro de quejas virtual</router-link>
                                </li>
                            </ul>
                            <img class="responsive-img" src="./../assets/safe_site">
                        </div>
                        <div class="col-6 col-md-3">
                            <p class="footer-title mb-2">Vías de contacto</p>
                            <ul class="list-unstyled">
                                <li>
                                    <router-link to="/contact">Dónde estamos</router-link>
                                </li>
                                <li>
                                    <router-link to="/phones">Teléfonos</router-link>
                                </li>
                                <li class="social">
                                    <p class="footer-subtitle m-0">Seguinos</p>
                                    <!--
                                    <a href="https://www.facebook.com/Ofidirect" target="_blank">
                                        <i class="icon mr-2 fab fa-facebook-square"></i>
                                    </a>
                                    <a href="https://twitter.com/ofidirect" target="_blank">
                                        <i class="icon fab fa-twitter-square"></i>
                                    </a>-->
                                    <a v-if="enableFB" class="social-button mr-1" title="Facebook"  target="_blank" rel="nofollow"  :href="linkFB" >
                                        <i class="icon fab fa-facebook-square"></i>
                                    </a>
                                    <a v-if="enableTW" class="social-button" title="Twitter" target="_blank" rel="nofollow" :href="linkTW">
                                        <i class="icon fab fa-twitter-square"></i>
                                    </a>
                                    <a v-if="enableIG" class="social-button" title="Instagram" target="_blank" rel="nofollow" :href="linkIG" >
                                        <i class="icon fab fa-instagram-square"></i>
                                    </a>
                                     <a v-if="enableLK" class="social-button" title="LinkedIn" target="_blank" rel="nofollow" :href="linkLK" >
                                        <i class="icon fab fa-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 col-md-3">
                            <p class="footer-title mb-2">Más Ofidirect</p>
                            <ul class="list-unstyled">
                                <li><router-link to="/category/12__Ofertas?category=12__Ofertas">Ofertas y Promociones</router-link></li>
                                <li><p class="footer-subtitle m-0">Teléfono</p> <strong>4876-5555</strong></li>
                                <li class="payment mt-3">
                                    <p class="footer-subtitle mb-1 d-inline-block">Medios de pago</p>
                                    <img src="/static/ecommerce/img/mercado-pago-iso.png">
                                    <div class="tarjetas-credito">
                                        <p class="mb-1">Tarjeta de crédito en hasta 12 cuotas</p>
                                        <ul>
                                            <li class="paymentmethod visa">Visa</li>
                                            <li class="paymentmethod master">Mastercard</li>
                                            <li class="paymentmethod amex">American Express</li>
                                            <li class="paymentmethod mercadopago_cc">Mercado Pago + Banco Patagonia</li>
                                            <li class="paymentmethod naranja">Naranja</li>
                                            <li class="paymentmethod tarshop">Tarjeta Shopping</li>
                                            <li class="paymentmethod nativa">Nativa</li>
                                            <li class="paymentmethod cencosud">Cencosud</li>
                                            <li class="paymentmethod cabal">Cabal</li>
                                            <li class="paymentmethod argencard">Argencard</li>
                                            <li class="paymentmethod diners">Diners</li>
                                            <li class="paymentmethod cordobesa">Cordobesa</li>
                                            <li class="paymentmethod cmr">CMR</li>
                                            <li class="paymentmethod cordial">Cordial</li>
                                        </ul>
                                    </div>
                                    <div class="tarjetas-debito mt-2">
                                        <p class="mb-1">Tarjeta de débito</p>
                                        <ul>
                                            <li class="paymentmethod visadeb">Visa Débito</li>
                                            <li class="paymentmethod masterdeb">Mastercard Débito</li>
                                            <li class="paymentmethod maestro">Maestro</li>
                                            <li class="paymentmethod cabaldeb">Cabal Débito</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 col-md-3 text-center">
                            <div class="mb-3">
                                <a href="//qr.afip.gob.ar/?qr=JAWopgFbswaOWCaryUkZZg,," target="_F960AFIPInfo">
                                    <img src="//www.afip.gob.ar/images/f960/DATAWEB.jpg" class="afip-img img-fluid" style="max-width: 80px;" >
                                </a>
                            </div>
                            <div class="text-center brand-container">
                                <span class="right">with <i class="icon fas fa-heart"></i> by <a href="https://oppen.io/" target="_blank" class="brand">öppen</a></span>
                            </div>
                        </div>
                    </div>
                  </div>
            </footer>`;
    }
}

FooterSectionCustomComponent.registerComponent();

/*Vue.component('footerSectionComponent', {
    data : function(){
        return {year:new Date().getFullYear()}
    },
    computed:{
        menuTitle:function(){
            return this.$store.getters.menuTitle;
        },
        menuSubTitle:function(){
            return this.$store.getters.menuSubTitle;
        },
        mustRender:function(){
            return this.$store.getters.settingsIsLoaded;
        }
    },
    template: ,
});*/
